import { toast } from 'react-toastify';

import Event from './Event';

export const navigate = (pathname, state) => {
  Event.emit('app:navigate', { pathname, state });
};

export const navigateHandler = handler => {
  Event.on('app:navigate', handler);
};

export const addNotification = ({ message, variant = 'warning' }) => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: variant
  });
};
