import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CodeAction from '../Store/Code';

export default ({ user, onBack, setUser }) => {
  const validateCodeEmail = () => {
    CodeAction.validateEmailCode(user._id, user.emailCode).then(() => {
      if (user.phoneStatus === 'Verificado') {
        return onBack();
      }
      setUser({ emailStatus: 'Verificado' });
    });
  };
  const sendCodeEmail = () => {
    CodeAction.createEmailCode(user._id, user.email);
  };

  return (
    <>
      <Col xs="12">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Email *</Form.Label>
          <Form.Control type="text" value={user.email} onChange={evt => setUser({ email: evt.target.value })} />
        </Form.Group>
      </Col>

      <Col xs="12">
        <Row>
          <Col xs="12">
            <Button variant="primary" type="button" onClick={sendCodeEmail} className="btn-code">
              Enviar Codigo al Email
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs="12">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Código de Email *</Form.Label>
          <br />
          <Form.Label>
            <small>Recibirá por email el código a verificar</small>
          </Form.Label>
          <Form.Control
            type="number"
            value={user.emailCode}
            onChange={evt => setUser({ emailCode: evt.target.value })}
          />
        </Form.Group>
      </Col>

      <Col xs="12">
        <Row>
          <Col xs="12">
            <Button variant="success" type="button" onClick={validateCodeEmail} className="btn-code">
              Validar Codigo de Email
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};
