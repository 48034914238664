import { useState, useEffect } from 'react';

export const useAction = (action, ...params) => {
  const [state, setState] = useState({});

  useEffect(() => {
    let didCancel = false;

    const callAction = async () => {
      setState({ loading: true });

      try {
        const data = await action(...params);
        if (!didCancel) setState({ data });
      } catch (error) {
        if (!didCancel) setState({ error });
      }
    };

    callAction();

    return () => {
      didCancel = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...params]);

  return state;
};

export default {
  useAction
};
