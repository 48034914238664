const local = {};

export const setDispatch = dispatch => {
  local.dispatch = dispatch;
};

export const setState = state => {
  local.state = state;
};

export const getState = () => {
  return local.state;
};

export const dispatch = (...args) => local.dispatch(...args);
