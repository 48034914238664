import React, { useEffect } from 'react';

import Visibility from '../../Store/Visibility';

export default ({ children, onVisibilityChange }) => {
  useEffect(() => {
    const handler = () => {
      const status = !document.hidden;
      if (onVisibilityChange) {
        onVisibilityChange(status);
      }

      Visibility.setWindowIsActive(status);
    };
    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
