import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CodeAction from '../Store/Code';
import { addNotification } from '../services';

export default ({ user, onBack, setUser }) => {
  const sendCodeSms = () => {
    CodeAction.createSmsCode(user._id, user.phone);
  };

  const validateCodeSms = () => {
    CodeAction.validateSmsCode(user._id, user.phoneCode).then(() => {
      if (user.emailStatus === 'Verificado') {
        addNotification({
          message: 'Se registró correctamente. Le llegará un mail cuando su usuario está activo.',
          variant: 'success'
        });
        return onBack();
      }
      setUser({ phoneStatus: 'Verificado' });
    });
  };

  return (
    <>
      <Col xs="12">
        <Form.Group controlId="formGroupPhone">
          <Form.Label>Teléfono *</Form.Label>
          <br />
          <Form.Label>
            <small>Ej.: Real (011) 15-2233-4455 {'-->'} Escribir 1122334455</small>
          </Form.Label>
          <Form.Control type="text" value={user.phone} onChange={evt => setUser({ phone: evt.target.value })} />
        </Form.Group>
      </Col>

      <Col xs="12">
        <Row>
          <Col xs="12">
            <Button variant="primary" type="button" onClick={sendCodeSms} className="btn-code">
              Enviar Codigo al Teléfono
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs="12">
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Código de Teléfono *</Form.Label>
          <br />
          <Form.Label>
            <small>Recibirá por sms el código a verificar</small>
          </Form.Label>
          <Form.Control
            type="number"
            value={user.phoneCode}
            onChange={evt => setUser({ phoneCode: evt.target.value })}
          />
        </Form.Group>
      </Col>

      <Col xs="12">
        <Row>
          <Col xs="12">
            <Button variant="success" type="button" onClick={validateCodeSms} className="btn-code">
              Validar Codigo al Teléfono
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};
