import React from 'react';

import VisibilitySensor from 'react-visibility-sensor';

export default ({ onLoadMore, children }) => {
  return (
    <VisibilitySensor
      onChange={isVisible => {
        if (isVisible && onLoadMore) onLoadMore();
      }}
    >
      {children}
    </VisibilitySensor>
  );
};
